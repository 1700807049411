<template>
  <vs-card class="workout-edit" v-if="workout">
    <!-- <vs-row class="header">
      <vs-col class="header-icon">
        <vs-icon icon="check_circle"></vs-icon>
      </vs-col>
      <vs-col class="header-text"></vs-col>
      <vs-col class="header-actions"></vs-col>
    </vs-row>-->

    <vs-row class="workout-items" vs-w="12">
      <!-- workout sumary-->
      <vs-col class="workout-sumary" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <div
          :class="{'workout-status':true,'missed':workout.state=='missed', 'completed':workout.state=='completed', 'pending':workout.state=='pending'}"
        >
          <span>{{titleText}}</span>
          <!-- <vs-icon
            icon="delete"
            @click.native="deleteWorkout()"
            size="small"
            class="cursor-pointer workout-delete"
          /> -->
        </div>
      </vs-col>
      <vs-col class="workout-sumary" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button
          v-if="workout.id && workout.state=='pending' || workout.state=='missed'"
          @click="switchWorkoutState('completed')"
          color="success"
          icon="done"
          type="border"
          class="action-buttons"
        >标识为“完成”</vs-button>
        <vs-button
          v-if="workout.id && workout.state=='pending' || workout.state=='completed'"
          @click="switchWorkoutState('missed')"
          color="danger"
          icon="close"
          type="border"
          class="action-buttons"
        >标识为“错过”</vs-button>
      </vs-col>

      <!-- workout title-->
      <vs-col
        class="workout-title"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <input v-model="workout.title" placeholder="输入标题" :disabled="!editable" />
      </vs-col>

      <!-- workout warmup description -->
      <vs-col class="workout-warmup">
        <vs-divider>热身动作：</vs-divider>

        <textarea
          ref="textarea"
          v-if="editable"
          v-model="workout.warmup"
          @keydown="textAreaResize"
          placeholder="添加自定义热身动作"
        ></textarea>
        <span v-else v-html="workout.warmup?workout.warmup.replace(/\n/g,'<br/>'):''"></span>

        <VueSimpleSuggest
          ref="warm_selection"
          v-if="editable && !workout.warmup"
          :list="warmupSuggestionList"
          :filter-by-query="true"
          @select="copyFromWarmup"
          placeholder="引用标准热身运动"
          class="suggestion_list"
        />
      </vs-col>
      <vs-col
        v-if="editable && workout.warmup"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <VueSimpleSuggest
          :list="exerciseSuggestionList"
          :filter-by-query="true"
          @select="linkWarmupExercise"
          placeholder="链接更多演示视频"
          v-model="warmupVideo"
          class="suggestion_list"
        />
      </vs-col>
      <vs-col
        v-if="editable"
        class="videolist"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-chip
          color="dark"
          v-for="wue in warmupExercises"
          :key="wue.id"
          closable
          @click="removeWarmupExercise(wue)"
        >
          <vs-avatar
            color="danger"
            @click="warmupExerciseID = warmupExerciseID?null:wue.id"
            icon="photo_camera"
          />
          {{wue.name}}
        </vs-chip>
      </vs-col>

      <vs-col
        v-if="warmupExerciseID"
        class="videolist"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <div class="itemPlayer">
          <video-player
            ref="WarmupPlayer"
            class="media-video-player"
            :options="videoPlayerOptions('warmup')"
          />
        </div>
      </vs-col>

      <!-- workout items -->
      <draggable handle=".handle" :list="workoutItems" @end="workoutItemOnMove">
        <vs-col
          v-for="(workout_item, wkIndex) in workoutItems"
          :key="wkIndex"
          vs-type="flex"
          class="workout-item"
        >
          <div class="workout-item-left-actions" v-if="editable">
            <vs-icon
              icon="drag_indicator"
              size="small"
              color="#c1c2c7"
              class="handle cursor-pointer"
            />
            <vs-icon
              icon="delete"
              @click.native="deleteWorkoutItem(workout_item)"
              size="small"
              color="#c1c2c7"
              class="cursor-pointer"
            />
          </div>

          <div class="workout-item-content">
            <div class="workout-item-name">
              <span>{{numberToChar(wkIndex, 0)}})</span>
              <!-- <input v-model="workout_item.name" :disabled="!editable" /> -->
              <VueSimpleSuggest
                v-if="editable"
                ref="vss_exercise"
                v-model="workout_item.name"
                :list="exerciseSuggestionList"
                :filter-by-query="true"
                :styles="suggestStyles"
                placeholder="训练标题（必须输入）"
                class="suggestion_list"
                mode="input"
                @select="exerciseSelected(workout_item)"
              />
              <span v-else>{{workout_item.name}}</span>
            </div>
            <div class="workout-item-info">
              <textarea
                ref="textarea"
                v-if="editable"
                v-model="workout_item.info"
                placeholder="设置，循环，时间，休息等"
                @keydown="textAreaResize"
              ></textarea>
              <span v-else v-html="workout_item.info.replace(/\n/g,'<br/>')"></span>
            </div>
            <div class="itemPlayer" v-if="workout_item && workout_item.name && showItemPlayer[wkIndex]">
              <video-player
                class="media-video-player"
                :options="itemVideoPlayerOptions(workout_item)"
              />
            </div>
            <div v-if="editable" class="workout-item-result">
              <input v-if="editable" v-model="workout_item.result" placeholder="输入结果" />
              <div class="item-state">
                <span
                  @click="switchWorkoutItemState(workout_item)"
                  :class="{'item-state-icon':true,'missed':workout_item.state=='missed', 'completed':workout_item.state=='completed', 'pending':workout_item.state=='pending'}"
                />
              </div>
            </div>
            <div v-if="!editable && workout_item.result" class="workout-item-result view">
              <span>{{workout_item.result}}</span>
            </div>
          </div>

          <div class="workout-item-right-actions">
            <vs-icon
              v-if="editable && workout_item.name &&  (workout_item.exercise_id || workout_item.attachments.length)"
              @click.native="showItemVideoPlayer(wkIndex, workout_item)"
              :icon="showItemPlayer[wkIndex]?'videocam_off':'videocam'"
              size="small"
              color="#7c858d"
              class="cursor-pointer"
            />
            <vs-icon
              v-if="editable && !workout_item.exercise_id && !workout_item.attachments.length && workout_item.name"
              @click.native="uploadVideoForItem(workout_item)"
              icon="video_call"
              size="small"
              color="#7c858d"
              class="cursor-pointer"
            />
            <!-- <vs-icon
              @click.native="switchWorkoutItemState(workout_item)"
              :icon="workoutItemStateIcon(workout_item)"
              size="small"
              :color="workoutItemStateColor(workout_item)"
              class="cursor-pointer"
            />-->
            <!-- <vs-icon
              v-if="editable"
              icon="history"
              size="small"
              color="#7c858d"
              class="cursor-pointer"
            />-->
          </div>
        </vs-col>
      </draggable>

      <vs-col v-if="editable" vs-type="flex" vs-justify="center" class="workout-item">
        <vs-button
          @click.stop="addWorkoutItem"
          class="workout-actions-button"
          icon="add"
          color="#d8dbdf"
          text-color="#000"
        >增加练习</vs-button>
      </vs-col>

      <!-- cooldown -->
      <vs-col class="workout-cooldown">
        <vs-divider>放松动作：</vs-divider>

        <textarea
          ref="textarea"
          v-if="editable"
          v-model="workout.cooldown"
          placeholder="添加自定义放松动作"
          @keydown="textAreaResize"
        ></textarea>
        <span v-else-if="workout.cooldown" v-html="workout.cooldown.replace(/\n/g,'<br/>')"></span>

        <VueSimpleSuggest
          ref="warm_selection"
          v-if="editable && !workout.cooldown"
          :list="cooldownSuggestionList"
          :filter-by-query="true"
          @select="copyFromCoolDown"
          placeholder="引用标准放松运动"
          class="suggestion_list"
        />
      </vs-col>
      <vs-col
        v-if="editable && workout.cooldown"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <VueSimpleSuggest
          ref="cooldown_selection"
          :list="exerciseSuggestionList"
          :filter-by-query="true"
          @select="linkCooldownExercise"
          placeholder="链接更多演示视频"
          class="suggestion_list"
          v-model="cooldwonVideo"
        />
      </vs-col>
      <vs-col
        v-if="editable"
        class="videolist"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-chip
          color="dark"
          v-for="wue in cooldownExercises"
          :key="wue.id"
          closable
          @click="removeCooldownExercise(wue)"
        >
          <vs-avatar
            color="danger"
            @click="cooldownExerciseID = cooldownExerciseID?null:wue.id"
            icon="photo_camera"
          />
          {{wue.name}}
        </vs-chip>
      </vs-col>

      <vs-col
        v-if="cooldownExerciseID"
        class="videolist"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <div class="itemPlayer">
          <video-player
            ref="CooldownPlayer"
            class="media-video-player"
            :options="videoPlayerOptions('cooldown')"
          />
        </div>
      </vs-col>

      <!-- save & cancel -->
      <vs-col class="workout-actions">
        <vs-button
          v-if="editable"
          @click.stop="save"
          color="primary"
          type="filled"
          :disabled="!isValidate"
          class="workout-actions-button"
        >保存</vs-button>
        <vs-button
          v-if="editable"
          @click.stop="close"
          color="#a4b5c4"
          type="flat"
          class="workout-actions-button"
        >取消</vs-button>
      </vs-col>

      <!-- comment -->
      <vs-col class="workout-comment-list" v-for="(cmt, cmtIdx) in commentList" :key="cmtIdx">
        <!-- <vs-divider>评论：</vs-divider> -->
        <div class="comment-user-icon">
          <vs-avatar :src="getUserImageURL(cmt.user_id)" :text="getUserName(cmt.user_id)" />
        </div>
        <div class="comment-user-name">
          <span class="comment-name">{{getUserName(cmt.user_id)}}</span>
          <span class="comment-time">{{timePast(cmt.created_at)}}以前</span>
        </div>
        <div class="comment-action">
          <vs-icon
            icon="delete"
            @click.native="deleteComment(cmt)"
            size="small"
            class="cursor-pointer"
          ></vs-icon>
        </div>
        <div class="comment-body">
          <span v-html="cmt.body?cmt.body.replace(/\n/g,'<br/>'):''" class="comment"></span>
        </div>
        <div class="comment-attachment" v-for="(atc, atcIdx) in cmt.attachments" :key="atcIdx">
          <img v-if="atc.type.indexOf('image')>=0" :src="atc.attachment_url" style="max-width:90%" />
          <video-player v-else class="media-video-player" :options="playerOptions(atc, 450)" />
        </div>
      </vs-col>

      <!-- add comment -->
      <vs-col class="workout-comment">
        <vs-divider>添加评论：</vs-divider>
        <textarea
          ref="textarea"
          v-if="workout && workout.id && clientUser"
          v-model="newComment.body"
          :placeholder="'给'+clientUser.name+'一些鼓励！'"
          @keydown="textAreaResize"
        ></textarea>
        <div v-for="(atc, atcIdx) in attachments" :key="atcIdx">
          <img v-if="atc.type.indexOf('image')>=0" :src="atc.attachment_url" style="max-width:90%" />
          <video-player v-else class="media-video-player" :options="playerOptions(atc, 450)" />
        </div>
      </vs-col>
      <vs-col
        class="workout-comment-action"
        vs-type="flex"
        vs-justify="space-between"
        vs-align="center"
        vs-w="12"
      >
        <div>
          <vs-icon
            @click.native="uploadVideoForComments"
            icon="add_a_photo"
            size="small"
            class="cursor-pointer"
          />
        </div>
        <div>
          <vs-button :disabled="newComment.body?false:true" @click="saveComment">发表评论</vs-button>
        </div>
      </vs-col>
    </vs-row>

    <!--file upload-->
    <vs-col vs-w="2">
      <input type="file" id="files" ref="files" @change="fileSelected()" style="visibility:hidden" />
    </vs-col>
  </vs-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS

// import TextareaAutosize from "vue-textarea-autosize";

import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

import draggable from "vuedraggable";

export default {
  mixins: [baseFun],
  props: ["showPopup", "workout_org"],

  data() {
    return {
      workout: null,
      clientUser: null,
      chosenWarmup: null,
      chosenExercise: null,
      chosenCooldown: null,
      workoutItems: [],
      maxPosition: 0,
      suggestStyles: {
        defaultInput: "suggestInput"
      },

      warmupExerciseID: null,
      cooldownExerciseID: null,

      playerDefOptions: {
        autoplay: false,
        muted: true,
        language: "zh",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: []
      },
      showItemPlayer: [],
      workoutItemIDs: [],

      newComment: null,
      attachments: [],

      warmupVideo: "",
      warmupVideoSelected:false,

      cooldwonVideo: "",
      cooldownVideoSelected:false,

      // for file upload
      currentWorkoutItem: null
    };
  },

  watch: {
    chosenWarmup(newVal) {
      if (newVal) {
        //
      }
    },

    workout_org(newVal) {
      this.getBaseInfo(newVal);
    },

    warmupVideo(newVal){
      if (newVal && this.warmupVideoSelected){
        this.warmupVideoSelected = false;
        this.warmupVideo = "";
      }
    },

    cooldwonVideo(newVal){
      if (newVal && this.cooldownVideoSelected){
        this.cooldownVideoSelected = false;
        this.cooldwonVideo = "";
      }
    }
  },

  created() {
    this.getBaseInfo();
  },

  computed: {

    editable() {
      return this.workout ? this.workout.state == "pending" : false;
    },

    isValidate() {
      let bRtn = true;
      this.workout.workout_items.forEach(item => {
        if (!item.name) bRtn = false;
      });
      return bRtn;
    },

    titleText() {
      return this.workout
        ? this.workout.due.substring(0, 10) +
            " " +
            this.stateText(this.workout.state)
        : "";
    },

    videoList() {
      let aryVideoNames = [];
      return this.exercises.forEach(itm => {
        aryVideoNames.push(itm.name);
      });
    },

    warmupExercises() {
      return this.workout ? this.workout.warmup_selected_exercises : [];
    },

    exerciseSuggestionList() {
      let list = [];
      let organization_id = this.activeOrganization.id;
      if (this.exercises)
        this.exercises.forEach(item => {
          if (item.organization_id == organization_id)
            list.push(item.exercise_name);
        });
      return list;
    },

    warmupSuggestionList() {
      let list = [];
      let organization_id = this.activeOrganization.id;
      if (this.warmups)
        this.warmups.forEach(item => {
          if (item.organization_id == organization_id) list.push(item.name);
        });
      return list;
    },

    cooldownExercises() {
      return this.workout ? this.workout.cooldown_selected_exercises : [];
    },

    cooldownSuggestionList() {
      let list = [];
      let organization_id = this.activeOrganization.id;
      if (this.cooldowns)
        this.cooldowns.forEach(item => {
          if (item.organization_id == organization_id) list.push(item.name);
        });
      return list;
    },

    hasEditableWarmup() {
      return (
        this.workout &&
        this.workout.state == "pending" &&
        this.workout.warmup != "" &&
        this.workout.warmup != null
      );
    },

    commentList() {
      return this.workout && this.workout.comment_ids && this.comments
        ? this.comments.filter(item =>
            this.workout.comment_ids.includes(item.id)
          )
        : [];
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "exercises",
      "warmups",
      "cooldowns",
      "workouts",
      "workout_items",
      "comments",
      "clients",
      "users"
    ])
  },

  mounted() {},

  methods: {
    getBaseInfo(){
      this.workout = JSON.parse(JSON.stringify(this.workout_org));
      // if (this.workout) this.workout.due = this.workout.due.substring(0,10)
      this.workoutItems = this.workout ? this.workout.workout_items : [];
      this.workoutItems.sort((a, b) => a.position - b.position);
      this.maxPosition = this.workout ? this.workoutItems.length : 0;
      this.showItemPlayer.length = this.workout
        ? this.workout.workout_items.length
        : 0;
      this.showItemPlayer.fill(false, 0);

      this.newComment = this.getNewComment(
        this.workout.id,
        this.activeTrainer.user_id
      );

      let client = this.workout?this.getClient(this.workout.client_id):null;
      this.clientUser = client?this.getUser(client.user_id):null;
    },

    // // get workout_item list for a workout
    // getWorkoutItems() {
    //   if (
    //     !this.workout ||
    //     !this.workout.workout_item_ids ||
    //     !this.workout_items
    //   )
    //     return [];

    //   let ids = this.workout.workout_item_ids;
    //   let items = this.workout_items.filter(item => ids.includes(item.id));

    //   return items;
    // },

    deleteWorkout() {
      this.$vs.dialog({
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否确认删除此workout？ ',
        accept:this.deleteWorkoutProcess
      })
    },

    deleteWorkoutProcess() {
      if (this.workout.id) {
        this.$store.dispatch("deleteWorkout", this.workout.id);
      } else {
        //this.workout = JSON.parse(JSON.stringify(this.workout_org));
        this.$emit("reset");
      }
      this.$emit("reload");
      this.$emit("hide");
    },

    textAreaResize(event) {
      if (event.keyCode == 13) {
        this.$nextTick(() => {
          let contentHeight = event.target.scrollHeight;

          const heightVal = contentHeight + "px";
          event.target.style.height = 'auto';
          event.target.style.height = `${heightVal}`;
        });
      }
    },

    switchWorkoutState(state) {
      this.workout.state = state;
      if (this.workout.id) {
        this.$store.dispatch("updateWorkout", { workout: this.workout });
      }
    },

    exerciseSelected(workoutItem) {
      let name = this.$refs.vss_exercise[workoutItem.position - 1].selected;
      let exercise = this.getExerciseByName(name);
      if (exercise) {
        workoutItem.selected_exercises = [
          { id: exercise.id, name: exercise.exercise_name }
        ];
        workoutItem.exercise_id = exercise.id;

      } else {
        workoutItem.selected_exercises = [];

        workoutItem.exercise_id = 0;
      }
    },

    playerOptions(video, width) {
      return {
        width: width ? width : "460",
        fluid: true,
        autoplay: false,
        muted: true,
        language: "zh",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{ type: video.type, src: video.attachment_url }]
      };
    },

    videoPlayerOptions(type) {
      let exercise = this.getExercises(
        type == "warmup" ? this.warmupExerciseID : this.cooldownExerciseID
      );
      let video = null;
      if (exercise && exercise.attachments && exercise.attachments.length > 0) {
        video = exercise.attachments[0];
      }
      let playerOptions = this.playerDefOptions;
      if (video) {
        playerOptions = this.playerOptions(video);
      }
      return playerOptions;
    },

    itemVideoPlayerOptions(workout_item) {
      // console.log("itemVideoPlayerOptions->" + workout_item.id)
      let playerOptions = this.playerDefOptions;
      let video = this.workoutItemVideo(workout_item);
      if (video) playerOptions = this.playerOptions(video);
      return playerOptions;
    },

    showItemVideoPlayer(idx, workout_item) {
      //toggle visibility
      let video = this.workoutItemVideo(workout_item);
      this.showItemPlayer.splice(
        idx,
        1,
        video ? !this.showItemPlayer[idx] : false
      );
    },

    removeWarmupExercise(ex_item) {
      let idx = this.workout.warmup_selected_exercises.indexOf(ex_item);
      if (idx >= 0) {
        this.workout.warmup_selected_exercises.splice(idx, 1);
      }

      //reset player
      if (ex_item.id == this.warmupExerciseID) {
        this.warmupExerciseID = null;
      }
    },

    //select warmup fom suggestion
    // copy the description & selected_exercises to workout
    copyFromWarmup(warm_name) {
      let item = this.getWarmupByName(warm_name);
      if (item) {
        //copy the desciption to workout.warmup
        this.workout.warmup = item.description;

        //copoy the selected_exercises to warmup_selected_exercises
        item.selected_exercises.forEach(itm => {
          let new_item = { id: itm.id, name: itm.name };
          if (
            !this.workout.warmup_selected_exercises.find(
              itm => itm.id == new_item.id
            )
          ) {
            this.workout.warmup_selected_exercises.push(new_item);
          }
        });
      }
    },

    copyFromCoolDown(cool_name) {
      let item = this.getCooldownByName(cool_name);
      if (item) {
        //copy the desciption to workout.cooldown
        this.workout.cooldown = item.description;

        //copoy the selected_exercises to cooldown_selected_exercises
        item.selected_exercises.forEach(itm => {
          let new_item = { id: itm.id, name: itm.name };
          if (
            !this.workout.cooldown_selected_exercises.find(
              itm => itm.id == new_item.id
            )
          ) {
            this.workout.cooldown_selected_exercises.push(new_item);
          }
        });
      }
    },

    //link more videos to warmup
    linkWarmupExercise(ex_name) {
      let item = this.getExerciseByName(ex_name);
      if (item) {
        let new_item = { id: item.id, name: item.exercise_name };
        if (
          !this.workout.warmup_selected_exercises.find(
            itm => itm.id == new_item.id
          )
        ) {
          this.workout.warmup_selected_exercises.push(new_item);
        }
        this.warmupVideoSelected = true;
      }
    },

    linkCooldownExercise(ex_name) {
      let item = this.getExerciseByName(ex_name);
      if (item) {
        let new_item = { id: item.id, name: item.exercise_name };
        if (
          !this.workout.cooldown_selected_exercises.find(
            itm => itm.id == new_item.id
          )
        ) {
          this.workout.cooldown_selected_exercises.push(new_item);
        }
        this.cooldownVideoSelected = true;
      }
    },

    removeCooldownExercise(ex_item) {
      let idx = this.workout.cooldown_selected_exercises.indexOf(ex_item);
      if (idx >= 0) {
        this.workout.cooldown_selected_exercises.splice(idx, 1);
      }

      //reset player
      if (ex_item.id == this.cooldownExerciseID) {
        this.cooldownExerciseID = null;
      }
    },

    addCooldownExercise(ex_name) {
      let item = this.getCooldownByName(ex_name);
      if (item) {
        let new_item = { id: item.id, name: item.name };
        if (
          !this.workout.cooldown_selected_exercises.find(
            itm => itm.id == new_item.id
          )
        ) {
          this.workout.cooldown_selected_exercises.push(new_item);
        }
      }
    },

    addWorkoutItem() {
      this.workoutItems.push(this.getNewWorkoutItem(++this.maxPosition));
      this.showItemPlayer.push(false);
    },

    deleteWorkoutItem(workout_item) {
      if (!workout_item) return;

      let idx = 0;

      // remove it from workout_item_ids
      if (workout_item && workout_item.id) {
        idx = this.workout.workout_item_ids.findIndex(
          itm => itm == workout_item.id
        );
        if (idx >= 0) {
          this.workout.workout_item_ids.splice(idx, 1);
          this.workoutItemIDs.push(workout_item.id);
          this.showItemPlayer.splice(idx, 1);
        }
      } else if (workout_item){
        //new item which is not save yet, no id
        idx = this.workout.workout_items.findIndex(
          itm => itm.position == workout_item.position
        )
      }

      // remove it from workout_items
      this.workout.workout_items.splice(idx, 1);
    },

    workoutItemStateIcon(workoutItem) {
      let classes = {
        completed: "check",
        pending: "schedule",
        missed: "close"
      };
      return classes[workoutItem.state];
    },

    workoutItemStateColor(workoutItem) {
      let colors = {
        completed: "green",
        pending: "gray",
        missed: "red"
      };
      return colors[workoutItem.state];
    },

    switchWorkoutItemState(workoutItem) {
      if (workoutItem.state == "pending") workoutItem.state = "completed";
      else if (workoutItem.state == "completed") workoutItem.state = "missed";
      else if (workoutItem.state == "missed") workoutItem.state = "completed";
    },

    workoutItemOnMove(/*evt*/) {
      // console.log(evt);
      let pos = 1;
      this.workoutItems.forEach(item => (item.position = pos++));
    },

    uploadVideoForItem(workoutItem) {
      this.currentWorkoutItem = workoutItem;
      this.$refs.files.click();
    },

    uploadVideoForComments() {
      this.currentWorkoutItem = null;
      this.$refs.files.click();
    },

    fileSelected() {
      let self = this;
      let file = this.$refs.files.files[0];
      let attachment = this.getNewAttachment(
        file.name,
        file.size,
        file.type,
        ""
      );

      let formData = new FormData();
      formData.append("file", file);

      let uploadPath = "";
      if (file.type.indexOf("image/") == 0) uploadPath = "/upload/image";
      if (file.type.indexOf("video/") == 0) uploadPath = "/upload/file";
      if (!uploadPath) {
        this.$vs.notify({
          title: "错误",
          text: "文件类型不支持",
          color: "danger",
          position: "top-right"
        });
        this.$refs.files.value = "";
        return;
      }

      axios
        .post(uploadPath, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(resp) {
          // console.log(resp.data)
          attachment.attachment_url = resp.data.url;
          if (self.currentWorkoutItem) {
            self.currentWorkoutItem.attachments = [attachment];
            self.currentWorkoutItem = null;
          } else {
            self.attachments.push(attachment);
          }
          self.$refs.files.value = "";

          // console.log("SUCCESS!!");
        })
        .catch(function(err) {
          // console.log("FAILURE!!" + err);
          self.$vs.notify({
            title: "错误",
            text: err,
            color: "danger",
            position: "top-right"
          });
          self.$refs.files.value = "";
        });
    },

    save() {
      //组装worktout
      this.workout.workout_items = this.workoutItems;

      //组装workout.short_description
      let short_description = [];
      let me = this;
      this.workoutItems.forEach((item, i) =>
        short_description.push(me.numberToChar(i) + ") " + item.name)
      );
      this.workout.short_description =
        this.workout.title + "<br/>" + short_description.join("<br/>");

      if (this.workout.id > 0) {
        this.$store.dispatch("updateWorkout", { workout: this.workout });
        if (this.workoutItemIDs.length > 0) {
          this.workoutItemIDs.forEach(itm =>
            this.$store.dispatch("deleteWorkoutItem", itm)
          );
          this.workoutItemIDs = [];
        }
      } else {
        this.$store.dispatch("createWorkout", { workout: this.workout });
      }

      this.$emit("save", { old: this.workout_org, new: this.workout });

      this.close();
    },

    close() {
      //this.workoutItemIDs = [];
      this.workout = this.workout_org;
      this.$emit("hide");
    },

    saveComment() {
      this.newComment.workout_id = this.workout.id;
      this.newComment.attachments = this.attachments;
      let payload = {
        comment: this.newComment
      };
      this.$store.dispatch("createComment", payload);
      this.$store.dispatch("getWorkouts", { ids: [this.workout.id] });

      this.newComment = this.getNewComment(
        this.workout.id,
        this.activeTrainer.id
      );
      this.attachments = [];
    },

    deleteComment(cmt) {
      this.$store.dispatch("deleteComment", cmt);
      this.$store.dispatch("getWorkouts", { ids: [this.workout.id] });
    }
  },

  components: {
    VueSimpleSuggest,
    videoPlayer,
    draggable
  }
};
</script>

<style scoped>
.workout-edit {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
.workout-edit span {
  font-size: 1rem;
}
.workout-sumary {
  position: relative;
}
.workout-delete {
  position: absolute;
  right: 20px;
}

.action-buttons {
  margin: 5px;
  padding: 5px !important;
}
.workout-items > div {
  width: 100%;
}
.workout-status {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
}
.workout-status.completed {
  background-color: green;
  color: white;
}
.workout-status.missed {
  background-color: crimson;
  color: white;
}
.workout-status.pending {
  background-color: #e7e7e7;
  color: black;
}

.workout-title {
  margin: 15px 0;
}
.workout-title input {
  width: 105%;
  border: none;
  text-align: center;
  padding: 15px;
  font-size: 1.5rem;
}

.workout-warmup textarea {
  width: 100%;
  min-height: 50px;
  border: none;
  overflow: hidden;
}

.videolist {
  padding: 10px 0;
}

.workout-item {
  border-top: 1px solid #e7e7e7;
  padding: 15px 0;
}

.workout-item-left-actions {
  width: 20px;
}

.workout-item-content {
  width: calc(100% - 40px);
  padding: 0 10px;
  font-size: 1rem;
}
.workout-item-name {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 115%;
}
.workout-item-name input {
  width: calc(100% - 30px);
  border: none;
  font-weight: bold;
}

.workout-item-info {
  font-size: 1rem;
}
.workout-item-info textarea {
  font-size: 1rem;
  width: 615px;
  max-width: 615px;
  min-width: 615px;
  min-height: 50px;
  border: none;
  overflow: hidden;
}

.workout-item-result {
  color: #a4b5c4;
  position: relative;
}
.workout-item-result.view {
  padding: 10px 0 10px 15px;
  margin: 10px 0 10px 15px;
  border-left: 5px solid green;
  background-color: #f3fef8;
}
.workout-item-result input {
  width: 100%;
  border: none;
  background-color: #e7e7e7;
  font-size: 1rem;
  padding: 15px;
  border-radius: 5px;
}
.item-state {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 3px;
}
.item-state-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.item-state-icon.pending {
  background: #a4b5c4;
}
.item-state-icon.completed {
  background: green;
}
.item-state-icon.missed {
  background: crimson;
}

.workout-item-right-actions {
  width: 20px;
}

.workout-cooldown textarea,
.workout-comment textarea {
  width: 615px;
  max-width: 615px;
  min-width: 615px;
  min-height: 50px;
  border: none;
  overflow: hidden;
}
.workout-comment-action {
  padding: 15px;
}

.workout-actions {
  padding: 15px 0;
  border-top: 1px solid #e7e7e7;
}
.workout-actions-button {
  margin: 0 15px;
}

.suggestion_list {
  width: 100%;
}

.itemPlayer {
  display: block;
  width: 100%;
}
.media-video-player .video-js {
  height: 320px;
  width: 320px;
}

.videoPlayer {
  z-index: 54000;
}

/* comment */
.comment-body{
  display: flex;
  width: 100%;
  justify-content: left;
}
.workout-comment-list {
  background-color: #f9fafa;
  border-top: 1px solid #e6e8ea;
}
.comment-user-icon {
  width: 50px;
  line-height: 50px;
  float: left;
  position: relative;
  top: 5px;
}
.comment-user-name {
  width: calc(100% - 100px);
  line-height: 50px;
  float: left;
}
.comment-name {
  font-weight: bold;
  font-size: 1rem;
}
.comment-time {
  font-size: 0.85rem !important;
  color: #aeb5bc;
  padding-left: 15px;
}
.comment-action {
  width: 50px;
  line-height: 50px;
  float: left;
  text-align: right;
  clear: right;
}
.workout-comment .comment {
  display: block;
}
</style>

<style>
.vs-card--content {
  font-size: 1rem;
}
.suggestInput {
  border: none !important;
}
textarea::placeholder,
input::placeholder {
  font-size: 0.9rem;
  color: #a4b5c4;
}
textarea{
  min-width: 615px;
  max-width: 615px;
}

.workout-edit .vue-simple-suggest.designed .input-wrapper input {
  border: none;
}
</style>
